<template>
  <div class="salesLeadsDetail-container">
    <el-drawer
      class="salesLeadsDetail-drawer"
      title="销售机会详情"
      :visible.sync="drawer"
      :append-to-body="true"
      direction="rtl"
      :before-close="handleClose"
    >
      <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="drawer-container">
        <div class="drawer-left">
          <el-form :model="editRuleForm" :rules="editRules" ref="editRuleForm" class="demo-ruleForm">
            <div class="detail-title">
              <div class="tit-info hoverEdit" @click="() => {editBtn('name', chanceInfo.name)}" v-if="editParams.filedName != 'name'">
                <span class="editColor">{{ (chanceInfo && chanceInfo.name) || "--" }}</span>
                <div v-if="chanceInfo.state=='0'" class="common-tableStatus-green-bgc ml">跟进中</div>
                <div v-if="chanceInfo.state=='1'" class="common-tableStatus-blue-bgc ml">已成单</div>
                <div v-if="chanceInfo.state=='2'" class="common-tableStatus-grey-bgc ml">已流失</div>
              </div>
              <el-form-item v-if="editParams.filedName == 'name'" class="edit-input-container" prop="name">
                <el-input class="edit-input" ref="name" placeholder="请输入名称" v-model="editRuleForm.name" @blur="() => {editCancelBtn('name')}">
                  <template slot="append">
                    <div @click="() => {editChanceDetail('name')}" class="cancel">确认</div>
                  </template>
                </el-input>
              </el-form-item>
            </div>
            <div class="detail-content">
              <div class="DisableEdit">
                客户名称:
                <span class="common-clr-0a6">{{(chanceInfo && chanceInfo.customerName) || "--"}}</span>
              </div>
              <el-form-item v-if="editParams.filedName == 'type'" class="edit-input-container" prop="type" label="业务类型:">
                <el-select class="edit-input" v-model="editRuleForm.type" ref="type" placeholder="请选择" @blur="() => {editCancelBtn('type')}" @change="(e) => {editChanceDetail('type')}" filterable >
                  <el-option v-for="item in allDict['12']" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <div v-if="editParams.filedName != 'type'" @click="() => {editBtn('type', chanceInfo.type)}" class="hoverEdit" >
                业务类型:
                <span class="editColor">{{(chanceInfo && chanceInfo.typeName) || "--"}}</span>
              </div>
              <el-form-item v-if="editParams.filedName == 'price'" class="edit-input-container" prop="price" label="销售机会金额:">
                <el-input class="edit-input" type="number" ref="price" placeholder="请输入内容" v-model="editRuleForm.price" @blur="() => {editCancelBtn('price')}">
                  <template slot="append" ><div @click="() => {editChanceDetail('price')} " class="cancel">确认</div></template>
                </el-input>
              </el-form-item>
              <div @click="() => {editBtn('price', chanceInfo.price)}" v-if="editParams.filedName != 'price'" class="hoverEdit">
                销售机会金额:
                <span class="editColor">{{(chanceInfo && chanceInfo.price) || "--"}}</span>
              </div>
              <!-- <el-form-item v-if="editParams.filedName == 'stage'" class="edit-input-container" prop="stage" label="销售阶段:">
                <el-select class="edit-input" v-model="editRuleForm.stage" ref="stage" placeholder="请选择" @blur="() => {editCancelBtn('stage')}" @change="(e) => {editChanceDetail('stage')}" filterable >
                  <el-option v-for="item in allDict['4']" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <div v-if="chanceInfo.state == '0' && editParams.filedName != 'stage'" @click="() => {editBtn('stage', chanceInfo.stage)}" class="hoverEdit" >
                销售阶段:
                <span class="editColor">{{(chanceInfo && chanceInfo.stageName) || "--"}}</span>
              </div>
              <div v-if="chanceInfo.state != '0'" class="DisableEdit" >
                销售阶段:
                <span class="common-clr-0a6">{{(chanceInfo && chanceInfo.stageName) || "--"}}</span>
              </div> -->
              <div class="DisableEdit">
                客户级别:
                <span class="common-clr-0a6">{{(chanceInfo && chanceInfo.levelName) || "--"}}</span>
              </div>
              <el-form-item v-if="editParams.filedName == 'stage'" class="edit-input-container" prop="stage" label="销售阶段:">
                <el-select class="edit-input" v-model="editRuleForm.stage" ref="stage" placeholder="请选择" @blur="() => {editCancelBtn('stage')}" @change="(e) => {editChanceDetail('stage')}" filterable >
                  <el-option v-for="item in allDict['4']" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <div v-if="editParams.filedName != 'stage'" @click="() => {editBtn('stage', chanceInfo.stage)}" class="hoverEdit" >
                销售阶段:
                <span class="editColor">{{(chanceInfo && chanceInfo.stageName) || "--"}}</span>
              </div>
              <el-form-item v-if="editParams.filedName == 'expectedTimeStr'" class="edit-input-container" prop="expectedTimeStr" label="预计成单时间:">
                <el-date-picker class="edit-input" ref="expectedTimeStr" v-model="editRuleForm.expectedTimeStr" type="date" value-format="yyyy-MM-dd" format="yyyy-MM-dd" @blur="() => {editCancelBtn('expectedTimeStr')}" @change="(e) => {editChanceDetail('expectedTimeStr')}" placeholder="选择日期"></el-date-picker>
              </el-form-item>
              <div @click="() => {editBtn('expectedTimeStr', chanceInfo.expectedTimeStr)}" v-if="editParams.filedName != 'expectedTimeStr'" class="hoverEdit">
                预计成单时间:
                <span class="editColor">{{ (chanceInfo && chanceInfo.expectedTimeStr) || "--" }}</span>
              </div>
              <div class="DisableEdit">
                客户划分:
                <span class="common-clr-0a6">{{(chanceInfo && chanceInfo.projectTypeName) || "--"}}</span>
              </div>
              <div class="DisableEdit">
                项目名称:
                <span class="common-clr-0a6">{{(chanceInfo && chanceInfo.projectName) || "--"}}</span>
              </div>
              <el-form-item v-if="editParams.filedName == 'remark'" class="edit-input-container" prop="remark" label="备注:">
                <el-input class="edit-input" ref="remark" placeholder="请输入内容" v-model="editRuleForm.remark" @blur="() => {editCancelBtn('remark')}">
                  <template slot="append"><div @click=" () => {editChanceDetail('remark')}" class="cancel">确认</div></template>
                </el-input>
              </el-form-item>
              <div @click="() => {editBtn('remark', chanceInfo.remark)}" v-if="editParams.filedName != 'remark'" class="noteHoverEdit" >
                <span class="noteName">备注:</span>
                <span class="editColor">{{(chanceInfo && chanceInfo.remark) || "--"}}</span>
              </div>
            </div>
          </el-form>
          <el-tabs class="tabsDetail" v-model="activeTab" @tab-click="handleClick">
            <el-tab-pane label="阶段任务" name="1">
              <div>
                <div class="ServiceFollow-content">
                  <div v-for="(item, index) in stageTaskList" :key="index">
                    <div class="ServiceFollow-item" v-if="item.groupTaskId == 1 && chanceInfo.stage == 13">
                      <el-checkbox :value="item.chanceState == 0 ? false : true" @change="(value) => chanceStateFun(value, item.id)">{{ item.name }}</el-checkbox>
                      <div v-if="item.chanceState == '1'">{{ `${item.createName}于${item.completeTime}完成` }}</div>
                    </div>
                    <div class="ServiceFollow-item" v-if="item.groupTaskId == 6 && chanceInfo.stage == 14">
                      <el-checkbox :value="item.chanceState == '0' ? false : true" @change="(value) => chanceStateFun(value, item.id)">{{ item.name }}</el-checkbox>
                      <div v-if="item.chanceState == '1'">{{ `${item.createName}于${item.completeTime}完成` }}</div>
                    </div>
                    <div class="ServiceFollow-item" v-if="item.groupTaskId == 10 && chanceInfo.stage == 15">
                      <el-checkbox :value="item.chanceState == 0 ? false : true" @change="(value) => chanceStateFun(value, item.id)" >{{ item.name }}</el-checkbox>
                      <div v-if="item.chanceState == '1'">{{ `${item.createName}于${item.completeTime}完成` }}</div>
                    </div>
                  </div>
                  <div class="feedback">
                    <div class="steps-container">
                      <div class="steps-left">服务阶段</div>
                      <div class="steps-center">
                        <el-steps :active="chanceInfo.stage == '13' ? 1 : chanceInfo.stage == '14' ? 2 : 3">
                          <el-step title="发现客户" icon="el-icon-s-help"></el-step>
                          <el-step title="需求跟进" icon="el-icon-s-help"></el-step>
                          <el-step title="商务洽谈" icon="el-icon-s-help"></el-step>
                        </el-steps>
                      </div>
                      <div v-if="chanceInfo.state == '1'">
                        <el-button class="common-screen-btn" disabled>已成单</el-button>
                      </div>
                      <div v-if="chanceInfo.state == '2'">
                        <el-button class="common-screen-btn" disabled>已流失</el-button>
                      </div>
                      <div v-if="chanceInfo.state == '0'">
                        <el-button :disabled="chanceInfo.stage != 15 || !nextStage" class="common-screen-btn" type="primary" @click="() =>{showNewOrder()}" >成单</el-button>
                        <!-- <el-popconfirm placement="top" title="确定流失吗？" @confirm="chanceLoss()"> -->
                          <el-button @click="() => { assignVisible = true }" class="common-screen-btn" slot="reference">流失</el-button>
                        <!-- </el-popconfirm> -->
                      </div>
                    </div>
                    <span @click="showFeedback = !showFeedback">共{{ feedbackList.length || "0" }}条反馈</span>
                    <el-button v-if="nextStage && chanceInfo.state == '0' && chanceInfo.stage != 15" class="common-screen-btn" type="primary" @click="() => chanceNextStep()">进入下一阶段</el-button>
                  </div>
                  <div class="feedback-content">
                    <el-input v-if="showFeedback" type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" placeholder="请输入内容" v-model="feedbackContent"></el-input>
                    <div>
                      <div class="upload-userIcon" v-if="showFeedback">
                        <el-button :loading="btnLoading" class="common-screen-btn" type="primary" style="margin-left: 0" >上传图片</el-button>
                        <input class="common-upload-input" type="file" @input="(e) => chooseFileBack(e, 'img')" />
                        <div class="fileNameText" v-if="pictureNameText">{{ pictureNameText }}</div>
                      </div>
                      <div class="upload-userIcon" v-if="showFeedback">
                        <el-button :loading="btnLoading" class="common-screen-btn" type="primary" style="margin-left: 0">上传文件</el-button>
                        <input class="common-upload-input" type="file" @input="(e) => chooseFileBack(e, 'xlsx')" />
                        <div class="fileNameText" v-if="fileNameText">{{ fileNameText }}</div>
                      </div>
                      <div class="commit" v-if="showFeedback">
                        <div class="commit-btn">
                          <el-button class="common-screen-btn" :loading="btnLoading" type="primary" @click="() => addFeedback()">提交</el-button>
                          <el-button class="common-screen-btn">取消</el-button>
                        </div>
                      </div>
                      <div v-if="feedbackList" class="SubmitPlan-container">
                        <div class="feedback-item" v-for="(item, index) in feedbackList" :key="index + 'feedback'">
                          <div class="item-row">{{ item.planTime || "" }}</div>
                          <div class="item-row">
                            <el-button class="item-user" type="text" size="small">{{ item.createUser || "" }}</el-button>新增了反馈问题</div>
                          <div class="item-row" v-if="item.content">问题：{{ item.content }}</div>
                          <div style="margin-top: 16px" class="item-row">
                            <common-picture style="margin-right: 24px" v-if="item.picture" :fileUrl="item.picture" :fileType="'imgText'" :fileName="'反馈图片'"></common-picture>
                            <common-picture v-if="item.cmsFile" :fileUrl="item.cmsFile" :fileType="'file'" :fileName="'反馈文件'"></common-picture>
                          </div>
                          <!-- <div class="item-row">
                            <img class="feedbackImg" @click="() => downLoad(item.picture)" v-if="item.picture" :src="item.picture" alt="反馈" />
                            <img class="feedbackImg" @click="() => downLoad(item.cmsFile)" v-if="item.cmsFile" src="../../../assets/images/file_icon.png" alt="" />
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="基本信息" name="2">
              <div class="data-title">联系信息</div>
              <div class="detail-content">
                <div class="DisableEdit">手机号: <span class="common-clr-0a6">{{(cusInfo && cusInfo.phone) || "--"}}</span></div>
                <div class="DisableEdit">微信: <span class="common-clr-0a6">{{(cusInfo && cusInfo.wx) || "--"}}</span></div>
                <br/>
                <div class="DisableEdit">QQ: <span class="common-clr-0a6">{{(cusInfo && cusInfo.qq) || "--"}}</span></div>
                <div class="DisableEdit">公司网址: <span class="common-clr-0a6">{{(cusInfo && cusInfo.web) || "--"}}</span></div>
                <div class="DisableEdit">邮箱: <span class="common-clr-0a6">{{(cusInfo && cusInfo.mail) || "--"}}</span></div>
                <br/>
                <div class="DisableEdit">备注: <span class="common-clr-0a6">{{(cusInfo && cusInfo.note) || "--"}}</span></div>
              </div>
              <div class="data-title">系统信息</div>
              <div class="detail-content">
                <div class="DisableEdit">客户负责人: <span class="common-clr-0a6">{{(adminInfo && adminInfo.nickName) || "--"}}</span></div>
                <div class="DisableEdit">客户负责人所属部门: <span class="common-clr-0a6">{{(adminInfo && adminInfo.userDepartName) || "--"}}</span></div>
                <div class="DisableEdit">创建人: <span class="common-clr-0a6">{{(adminInfo && adminInfo.nickName) || "--"}}</span></div>
                <div class="DisableEdit">创建时间: <span class="common-clr-0a6">{{(chanceInfo && chanceInfo.createTime) || "--"}}</span></div>
                <div class="DisableEdit">最后修改人: <span class="common-clr-0a6">{{(chanceInfo && chanceInfo.createName) || "--"}}</span></div>
                <div class="DisableEdit">最后修改时间: <span class="common-clr-0a6">{{(chanceInfo && chanceInfo.updateTime) || "--"}}</span></div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="drawer-right">
          <div class="rightTop">
            <div>
              <img class="visitIcon" src="../../../assets/images/notice_img.png" />
              任务提醒
            </div>
            <div v-if="!planTask.length" class="notContent">暂无提醒</div>
            <div v-if="planTask.length && planTask.length > 1" class="notContent">您有 {{planTask.length}} 条计划即将开始</div>
            <div v-else v-for="(item, index) in planTask" :key="index" class="notContent">
              {{ item.content }}
            </div>
          </div>
          <div style="flex: 1;overflow: scroll;">
            <el-tabs style="height: 100%;display: flex;flex-direction: column;" v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="工作记录" name="about">
                <div class="about-content">
                  <div style="white-space: nowrap;">
                    跟进类型:
                    <el-select class="flowType" v-model="recordRadio" placeholder="请选择跟进类型">
                      <el-option :key="1" label="电话拜访" :value="1"> </el-option>
                      <el-option :key="2" label="网络拜访" :value="2"> </el-option>
                      <el-option :key="3" label="上门拜访" :value="3"> </el-option>
                    </el-select>
                  </div>
                   <br/>
                 <textarea class="common-textarea-log"  @paste="handlePaste" v-model="recordContent" placeholder="工作小计" rows="6"></textarea>
                  <!-- <el-input class="con-rec" :autosize="{ minRows: 6, maxRows: 6 }" type="textarea" v-model="recordContent"></el-input> -->
                  <!-- <div v-for="(item, index) in filesList" :key="index" class="fileNames">
                    <div>
                      <img v-if="item.fileType != 'img'" style="margin-right: 8px" src="../../../assets/images/img_icon.svg"/>
                      <img v-if="item.fileType == 'img'" style="margin-right: 8px" src="../../../assets/images/file_icon.svg" />{{ item.fileName }}
                    </div>
                    <div style="cursor: pointer;" @click="() => deleteFile(item.fileId)">删除</div>
                  </div> -->
                  <div class="fileNames" v-for="(item, index) in filesList" :key="index">
                    <div><common-picture :fileUrl="item.fileUrl" :fileType="item.fileType" :fileName="item.fileName"></common-picture></div>
                    <div class="common-blue-btn" @click="() => deleteFile(item.fileId)">删除</div>
                  </div>
                  <div class="con-rec">
                    <div>
                      <div class="addFileBtn">
                        <img style="margin-right: 8px" src="../../../assets/images/add_file_icon.svg" />添加附件
                        <input class="addFileInput" :multiple="false" @input="chooseFile" type="file" />
                      </div>
                      <div style="font-size: 12px; color: #ccc"> 上传文件及图片，大小不超过10M </div>
                    </div>
                    <el-button :loading="btnLoading" class="common-screen-btn" type="primary" @click="() => addChanceFollow()" >发布</el-button>
                  </div>
                </div>
                <common-work-log :followRecordList="followRecordList"></common-work-log>
              </el-tab-pane>
              <el-tab-pane label="下次跟进计划" name="data">
                <div>
                  <el-button class="common-screen-btn" @click=" () => {planAdd()} " type="primary">新建计划</el-button>
                  <div v-for="(item, index) in planList" :key="index" class="record-item">
                    <div class="item-top">
                      <div class="item-icon"></div>
                      <span class="item-role"> {{ item.planName }} </span>
                      <span class="item-date"> {{ item.createTime }} </span>
                    </div>
                    <div class="item-content">
                      <div>
                        <div v-if="item.sortLevel == 1" class="itemBtn">较低</div>
                        <div v-if="item.sortLevel == 2" class="itemBtn">普通</div>
                        <div v-if="item.sortLevel == 3" class="itemBtn">高优</div>
                        <div v-if="item.followType == 1" class="itemBtn">电话拜访</div>
                        <div v-if="item.followType == 2" class="itemBtn">网络拜访</div>
                        <div v-if="item.followType == 3" class="itemBtn">上门拜访</div>
                      </div>
                      <div v-if="item.planTime" class="item-detail">拜访日期：{{ item.planTime }}<img class="visitIcon" src="../../../assets/images/visit_icon.png" /></div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </el-drawer>
    <el-dialog
      v-dialogDrag
      title="生成订单"
      :append-to-body="true"
      :visible.sync="dialogVisible"
      width="520px"
    >
      <div>
        <el-form
          :model="ruleFormAdd"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item label="销售机会名称" prop="custName">
            {{ruleFormAdd.custName}}
          </el-form-item>
          <el-form-item label="订单名称" prop="name">
            <el-input
              class="dialog-input"
              v-model="ruleFormAdd.name"
              placeholder="订单名称"
              maxLength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="业务类型" prop="type">
            <el-select
              class="dialog-input"
              v-model="ruleFormAdd.type"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in allDict['12']"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单收款方式：" prop="paymentMethods">
            <el-select
              class="dialog-input"
              v-model="ruleFormAdd.paymentMethods"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in payType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="运营指派" prop="toId">
            <el-select
              class="dialog-input"
              v-model="ruleFormAdd.toId"
              placeholder="请选择指派人"
              filterable
            >
              <el-option
                v-for="item in assignPeople"
                :key="item.id"
                :label="item.nickName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="订单金额" prop="price">
            <el-input
              type="number"
              class="dialog-input"
              v-model="ruleFormAdd.price"
              placeholder="订单金额"
              maxLength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="订单开始时间" prop="startTime">
            <el-date-picker
              class="dialog-input"
              v-model="ruleFormAdd.startTime"
              type="date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              placeholder="选择开始时间"
              @change="(val) => { endPickerOptions = { disabledDate: (time) => { return time.getTime() < (new Date(val).getTime() - (3600 * 1000 * 24)) }} }"
              :picker-options="startPickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="订单结束时间" prop="endTime">
            <el-date-picker
              class="dialog-input"
              v-model="ruleFormAdd.endTime"
              type="date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              placeholder="选择结束时间"
              @change="(val) => { startPickerOptions = { disabledDate: (time) => { return time.getTime() >= new Date(val).getTime() }} }"
              :picker-options="endPickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="协议店铺数" prop="agreeShopNum">
            <el-input
              type="number"
              class="dialog-input"
              v-model="ruleFormAdd.agreeShopNum"
              placeholder="协议店铺数"
              maxLength="50"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="协议子账号数" prop="agreeSubNum">
            <el-input
              type="number"
              class="dialog-input"
              v-model="ruleFormAdd.agreeSubNum"
              placeholder="协议子账号数"
              maxLength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="协议日均咨询量" prop="conslutDayNum">
            <el-input
              type="number"
              class="dialog-input"
              v-model="ruleFormAdd.conslutDayNum"
              placeholder="协议单账号日均咨询量"
              maxLength="50"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="备注">
            <el-input
              class="dialog-input"
              type="textarea"
              v-model="ruleFormAdd.note"
              maxLength="500"
            ></el-input>
          </el-form-item>
          <el-form-item label="合同订单">
            <div class="orderFile-container">
              <div class="orderFileBtn">
                上传附件
                <input class="orderFile-input" type="file" @input="(e) => {orderFile(e)}">
              </div>
              上传文件及图片，大小不超过10MB
            </div>
            <div class="fileList-container">
              <div v-for="(item, index) in fileList.name" :key="index" class="fileList-item">
                <div class="item-name">
                  <!-- {{item}} -->
                  <common-picture :fileUrl="fileList.url[index]" :fileType="Config.fileType.imgType.includes(item.split('.')[item.split('.').length - 1]) ? 'img' : 'file'" :fileName="item"></common-picture>
                </div>
                <div @click="() => {deleteFlie(index)}" class="deleteBtn">
                  删除
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="checked">是否有介绍人</el-checkbox>
          </el-form-item>
          <div class="introducer" v-if="checked">
            <el-form-item label="介绍人名称">
              <el-input class="dialog-input" v-model="ruleFormAdd.introducerName" placeholder="介绍人名称" maxLength="50" ></el-input>
            </el-form-item>
            <el-form-item label="介绍人联系方式">
              <el-input class="dialog-input" v-model="ruleFormAdd.introducerPhone" placeholder="介绍人联系方式" maxLength="50"></el-input>
            </el-form-item>
            <el-form-item label="介绍费" prop="introducerPrice">
              <el-input class="common-input-YUAN" type="number" v-model="ruleFormAdd.introducerPrice" placeholder="介绍费" maxLength="50" ></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button
          :loading="btnLoading"
          class="common-screen-btn"
          type="primary"
          @click="() => subNewOrderForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      v-dialogDrag
      title="新建跟进计划"
      :append-to-body="true"
      :visible.sync="planVisible"
      width="520px"
    >
      <div  v-loading="loading" element-loading-text="数据较多，拼命加载中...">
        <el-form
          :model="planParams"
          :rules="planRules"
          ref="planParams"
          label-width="110px"
          class="demo-ruleForm"
        >
          <el-form-item label="计划名称" prop="planName">
            <el-input class="dialog-input" v-model="planParams.planName" maxLength="50" placeholder="请填写计划名称"></el-input>
          </el-form-item>
          <el-form-item label="跟进类型" prop="serviceId">
            <el-select class="dialog-input" v-model="planParams.followType" placeholder="请选择跟进类型">
              <el-option :key="1" label="电话拜访" :value="1"> </el-option>
              <el-option :key="2" label="网络拜访" :value="2"> </el-option>
              <el-option :key="3" label="上门拜访" :value="3"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="优先级" prop="serviceId">
            <el-select class="dialog-input" v-model="planParams.sortLevel" placeholder="请选择优先级">
              <el-option :key="1" label="较低" :value="1"> </el-option>
              <el-option :key="2" label="普通" :value="2"> </el-option>
              <el-option :key="3" label="高优" :value="3"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="拜访日期" prop="expectedTime">
            <el-date-picker class="dialog-input" v-model="planParams.planTime" type="datetime"  value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择时间" :picker-options="pickerOptions"></el-date-picker>
          </el-form-item>
          <el-form-item label="提醒时间" prop="serviceId">
            <el-select class="dialog-input" v-model="planParams.remind" placeholder="请选择提醒时间" filterable>
              <el-option :key="5" label="开始前五分钟" :value="5"> </el-option>
              <el-option :key="15" label="开始前十五分钟" :value="15"> </el-option>
              <el-option :key="30" label="开始前三十分钟" :value="30"> </el-option>
              <el-option :key="60" label="开始前一小时" :value="60"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input class="dialog-input" type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" v-model="planParams.remark" maxLength="500"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="planVisible = false">取 消</el-button>
        <el-button class="common-screen-btn" @click="ChanceNextPlan('planParams')" type="primary">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 捞取弹窗 -->
    <el-dialog v-dialogDrag :append-to-body="true" :visible.sync="assignVisible" width="520px">
      <template slot="title"><div style="display:flex;align-items: center;"><img src="~@/assets/images/if-exclamation@1x.png" class="imgs"><span class="diatit">机会流失</span> </div></template>
      <div class="diacon">确定流失此销售机会吗？流失后此客户将移动到线索公海列表内</div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="assignVisible = false">取 消</el-button>
        <el-button class="common-screen-btn" type="primary" @click="() => {chanceLoss()}">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { addChanceFollow, updateChance, addFeedback, chanceLoss, chanceNextStep, newOrder, ChanceNextPlan, chanceNextPlanList, planTaskList, editChanceDetail } from "../../../service/customer.js";
import { tableColumn, DateTransform, Config, rulePrice } from "../../../utils/index.js";
import { workLog } from "../../../service/common.js"
import CommonWorkLog from "../../../components/common/commonWorkLog.vue"
import CommonPicture from "../../../components/common/commonPicture.vue"
import { upload } from "../../../service/upload.js";
import { mapState } from "vuex"

export default {
  components: { CommonWorkLog, CommonPicture },
  props: [ "drawer", "tableRow", "chanceFollowList", "chanceList", "orderList", "adminInfo", "chanceInfo", "stageTaskList", "feedbackList", "nextStage", "assignPeople", "loading", "allDict", "cusInfo" ],
  data() {
    return {
      Config,
      followRecordList: [], // 工作日志
      checked: false,
      activeTab: '1',
      isShowInput: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - (1000 * 60 * 60 * 24);
        },
      },
      startPickerOptions: {},
      endPickerOptions: {},
      fileList: {
        name: [],
        id: [],
        url: []
      },
      planTask: [],
      planRules: {
        planName: [{ required: true, message: "请填写计划名称", trigger: "change" }],
      },
      planParams: {},
      editRuleForm: {
        customerName: "",
        type: "",
        price: "",
        levelName: "",
        stageName: "",
        stage: '',
        expectedTimeStr: "",
        projectTypeName: "",
        projectName: "",
        name: "",
        remark: ""
      },
      editRules: {
        stage: [{ required: true, message: "请选择", trigger: "change" }],
        price: [{ required: true, message: "请输入金额", trigger: "change" }, { validator: rulePrice, trigger: "change" },],
      },
      editParams: {
        filedName: "",
        filedValue: "",
      },
      planVisible: false,
      planList: [],
      filesList: [],
      tableColumn,
      DateTransform,
      ruleFormAdd: { // 生成订单
        type: 0
      }, // form提交数据
      rules: {
        // 校验规则
        name: [{ required: true, message: "请填写订单名称", trigger: "change" }],
        type: [{ required: true, message: "请选择业务类型", trigger: "change" }],
        custName: [{ required: true, trigger: "change" }],
        // toId: [{ required: true, message: "请选择指派人", trigger: "change" }],
        price: [{ required: true, message: "请填写金额", trigger: "change" }, { validator: rulePrice, trigger: "change" }],
        startTime: [{ required: true, message: "请选择开始时间", trigger: "change" }],
        endTime: [{ required: true, message: "请选择结束时间", trigger: "change" }],
        paymentMethods: [{ required: true, message: "请选择订单收款方式", trigger: "change" }],
      },
      active: "",
      activeName: "about", // tabs
      radioTab: "1",
      radio: "1",
      recordRadio: 1, // 跟进记录radio
      recordContent: "", // 跟进记录内容
      nowStage: 13, // 当前阶段
      feedbackContent: "", // 反馈内容
      fileName: "", // 反馈文件name
      pictureName: "", // 反馈图片
      pictureNameText: "", // 反馈图片名
      fileNameText: "", // 反馈文件名
      fileId: "", // 反馈文件id
      fileType: "", // 反馈文件类型
      pictureId: "",
      showFeedback: false,
      dialogVisible: false,
      nextPlan: {
        // 跟进计划
        state: 1,
        type: 1,
        remind: null,
        planTime: "",
        followTx: false,
      }, // 下次跟进计划提交数据
      ServiceFollow: {
        // 阶段任务
        checked0: false,
        checked1: true,
        checked2: true,
      },
      checkPlan: false, // 下次计划按钮
      options: [
        // 筛选下拉
        {
          value: 5,
          label: "5分钟",
        },
        {
          value: 15,
          label: "15分钟",
        },
        {
          value: 30,
          label: "30分钟",
        },
        {
          value: 60,
          label: "一小时",
        },
      ],
      payType: [
        {id: 0,name: '前置收款'},
        {id: 1,name: '前置服务'},
        {id: 2,name: '免费试用'},
      ],
      assignVisible: false
    };
  },
  computed: {
    ...mapState(["btnLoading"])
  },
  methods: {
    handlePaste(event) {
      const items = (event.clipboardData || window.clipboardData).items;
      let file = null;

      if (!items || items.length === 0) {
        this.$message.error("当前浏览器不支持本地");
        return;
      }
      // 搜索剪切板items
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") !== -1) {
          file = items[i].getAsFile();
          break;
        }
      }
      if (!file) {
        // this.$message.error("粘贴内容非图片");
        return;
      }
      this.chooseFile(file, 'copy')
    },
    async workLog(id) { // 工作日志
      let resData = (await workLog({id: id, type: 0})).data
      if (resData && resData.length) {
        resData.map((item) => {
          if (item.value) {
            item.value = JSON.parse(item.value)
          } else {
            item.value = []
          }
        })
      }
      this.followRecordList = resData
    },
    deleteFile (id) {
      let filesList = [...this.filesList]
      this.filesList = filesList.filter((item)=>{
        return item.fileId != id
      })
    },
    downLoad(url) {
      // 下载文件
      window.location.href = url;
    },
    deleteFlie (i) {
      let fileList = {...this.fileList}
      fileList.name = fileList.name.filter((item,index)=>{
        return i != index
      })
      fileList.id = fileList.id.filter((item,index)=>{
        return i != index
      })
      fileList.url = fileList.url.filter((item,index)=>{
        return i != index
      })
      this.fileList = fileList
    },
    showNewOrder () {
      this.fileList = {
        name: [],
        id: [],
        url: []
      }
      this.ruleFormAdd.type = this.chanceInfo.type || ''
      this.dialogVisible = true
    },
    async orderFile (e) {
      let fileList = {...this.fileList}
      let file = e.target.files[0];
      if (file.size / 1024 > 1024 * 10) {
        e.target.value = "";
        return this.$message.error("文件大小不能超过10M");
      }
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (!Config.fileType.PWEIType.includes(format)) {
        this.$emit("loadFun", false);
        e.target.value = "";
        return this.$message.error(`只能上传 ${Config.fileMessage.PWEIType} 格式的文件`)
      }
      // if ( format != "bmp" && format != "jpg" && format != "png" && format != "gif" && format != "jpeg" && format != "xls" && format != "xlsx" && format != "doc" && format != "docx" ) {
      //   this.$emit("loadFun", false);
      //   e.target.value = "";
      //   return this.$message.error(
      //     "支持上传bmp、jpg、png、gif、jpeg、xls、xlsx、doc、docx"
      //   );
      // }
      let fileName = file.name;
      let resData = (await upload({ file: file })).data;
      fileList.name.push(fileName)
      fileList.id.push(resData.id)
      fileList.url.push(resData.filename)
      this.fileList = fileList
    },
    editCancelBtn(name) {
      // 取消编辑
      setTimeout(() => {
        if (this.isShowInput == name) {
          this.editParams = {
            filedName: "",
            filedValue: "",
          };
          this.isShowInput = ''
        }
      }, 500)
    },
    editChanceDetail(name) {
      // 确认-提交修改
       let params = {
        chanceId: this.tableRow.id,
        customerId: this.tableRow.customer_id,
        filedName: name,
        filedValue: this.editRuleForm[name],
      };
        if (this.editParams.filedName) {
          this.$emit("loadFun", true);
          this.$refs["editRuleForm"].validateField(name, async (error) => {
            if (!error) {
              await editChanceDetail(params);
              this.$emit("chanceDetail", this.tableRow);
              this.editParams = {
                filedName: "",
                filedValue: "",
              };
            } else {
              this.$emit("loadFun", false);
              return false;
            }
          });
        }
    },
    async chooseFile(e, type) { // 上传文件
      let file
      if (type != 'copy') {
        file = e.target.files[0];
      } else {
        file = e
      }
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (file.size / 1024 > 1024 * 50) {
        if (type != 'copy') { e.target.value = "" }
        return this.$message.error('文件大小不能超过50M')
      }
      if (!Config.fileType.PWEIType.includes(format)) {
        if (type != 'copy') { e.target.value = "" }
        return this.$message.error(`只能上传 ${Config.fileMessage.PWEIType} 格式的文件`)
      }
      // let format = file.name
      this.$vuex.commit("btnLoading", true);
      let resData = (await upload({ file: file })).data;
      this.$vuex.commit("btnLoading", false);
      let fileItem = {
        fileType: Config.fileType.imgType.includes(file.name.split('.')[file.name.split('.').length - 1]) ? 'imgText' : 'file',
        fileName: file.name,
        fileId: resData.id,
        fileUrl: resData.filename,
      };
      // if (
      //   format == "bmp" ||
      //   format == "jpg" ||
      //   format == "png" ||
      //   format == "gif" ||
      //   format == "jpeg"
      // ) {
      //   fileItem.fileType = "img";
      // }
      this.filesList.push(fileItem);
      if (type != 'copy') { e.target.value = "" }
    },
    async chanceNextPlanList(chanceId, customerId) {
      // 下次跟进记录
      let resData = (await chanceNextPlanList({customerId: customerId, chanceId: chanceId})).data;
      this.planList = resData;
    },
    async planTaskList(chanceId, customerId) {
      // 计划提醒列表
      let resData = (await planTaskList({customerId: customerId, chanceId: chanceId})).data;
      this.planTask = resData;
    },
    async ChanceNextPlan(formName) {
      // 新建下次跟进计划提交数据
      this.$emit("loadFun", true);
      let planParams = { ...this.planParams };
      planParams.customerId = this.tableRow.customer_id;
      planParams.chanceId = this.tableRow.id;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          await ChanceNextPlan(planParams);
          this.chanceNextPlanList(this.tableRow.id, this.tableRow.customer_id);
          this.planTaskList(this.tableRow.id, this.tableRow.customer_id);
          this.$message.success("新建成功");
          this.planVisible = false;
          this.$emit("loadFun", false);
        } else {
          this.$emit("loadFun", false);
          return false;
        }
      });
      //
    },
    planAdd() {
      // 新建计划按钮
      this.planParams = {};
      this.planVisible = true;
      if (this.$refs.planParams) {
        this.$refs.planParams.resetFields();
      }
    },
    async chooseFileBack(e, type) {
      // 反馈上传文件
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (file.size / 1024 > 1024 * 10) {
        e.target.value = "";
        this.$message.error('文件大小不能超过10M')
      }
      if (!Config.fileType.PWEIType.includes(format)) {
        e.target.value = "";
        return this.$message.error(`只能上传 ${Config.fileMessage.PWEIType} 格式的文件`)
      }
      if (type == "img") {
        if (
          format != "bmp" &&
          format != "jpg" &&
          format != "png" &&
          format != "gif" &&
          format != "jpeg"
        ) {
          this.$message.error("仅支持bmp、png、jpg、jpeg、gif格式文件");
          e.target.value = "";
          return;
        }
      }
      if (type == "xlsx") {
        if (format != "doc" && format != "docx" && format != "xls" && format != "xlsx") {
          this.$message.error("仅支持doc、docx、xls、xlsx格式文件");
          e.target.value = "";
          return;
        }
      }
      this.$vuex.commit("btnLoading", true);
      let resData = (await upload({ file: file })).data;
      this.$vuex.commit("btnLoading", false);
      if (type == "img") {
        this.pictureName = resData.filename;
        this.pictureNameText = file.name;
        this.pictureId = resData.id;
      } else {
        this.fileName = resData.filename;
        this.fileNameText = file.name;
        this.fileId = resData.id;
      }
      this.fileType = type;
      e.target.value = ''
    },
    subNewOrderForm(formName) {
      // 成单
      let ruleFormAdd = { ...this.ruleFormAdd };
      let fileList = { ...this.fileList };
      this.$vuex.commit("btnLoading", true);
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (ruleFormAdd.custName) {
            delete ruleFormAdd.custName;
          }
          if (fileList && fileList.name) {
            ruleFormAdd.filedName = fileList.name.join(',')
          }
          if (fileList && fileList.id) {
            ruleFormAdd.filedIds = fileList.id.join(',')
          }
          if (fileList && fileList.url) {
            ruleFormAdd.filedUrl = fileList.url.join(',')
          }
          if (!this.checked) {
            if (ruleFormAdd.introducerName) {
              delete ruleFormAdd.introducerName;
            }
            if (ruleFormAdd.introducerPhone) {
              delete ruleFormAdd.introducerPhone;
            }
            if (ruleFormAdd.introducerPrice) {
              delete ruleFormAdd.introducerPrice;
            }
          }
          await newOrder(ruleFormAdd, ruleFormAdd.chanceId);
          this.$message.success("新增订单成功");
          this.$emit("handleClose");
          this.dialogVisible = false;
          this.$vuex.commit("btnLoading", false);
        } else {
          this.$vuex.commit("btnLoading", false);
          return false;
        }
      });
    },
    async chanceNextStep() {
      // 进入下一阶段
      let params = {
        step: this.chanceInfo.stage,
        chanceId: this.tableRow.id,
      };
      await chanceNextStep(params);
      this.$emit("chanceDetail", this.tableRow);
      this.$message.success("提交成功");
    },
    async chanceLoss() {
      // 订单流失
      await chanceLoss({}, this.tableRow.id);
      // this.$emit("chanceDetail", this.tableRow);
      this.$emit("handleClose");
      this.$message.success("订单已流失");
      this.assignVisible = false
    },
    async addFeedback() {
      // 提交反馈
      let params;
      // if (this.fileType == "img") {
      params = {
        chanceId: this.tableRow.id,
        content: this.feedbackContent,
        pictureId: this.pictureId,
        picture: this.pictureName,
        cmsFileId: this.fileId,
        cmsFile: this.fileName,
      };
      // } else {
      // params = {
      //   chanceId: this.tableRow.id,
      //   content: this.feedbackContent,

      // };
      // }
      await addFeedback(params);
      this.$message.success("提交成功");
      this.feedbackContent = "";
      this.pictureId = ""
      this.pictureName = ""
      this.fileId = ""
      this.fileName = ""
      this.pictureNameText = ""
      this.fileNameText = ""
      this.$emit("chanceDetail", this.tableRow);
    },
    getNowStage(row) {
      // 父组件传默认值
      this.nowStage = row.stage;
      this.ruleFormAdd = {
        customerId: row.customer_id,
        custName: row.name,
        chanceId: row.id,
        shopId: 0,
        type: row.type || ''
        // name: row.name,
        // price: row.price,
        // startTime: row.startTime,
        // endTime: row.endTime,
        // note: row.note,
      };
    },
    async addChanceFollow() {
      // 跟进发布按钮
      // 跟进发布按钮
      let filesList = [...this.filesList]
      let filesUrl = []
      let filesId = []
      let filesName = []
      filesList.map((item) => {
        filesUrl.push(item.fileUrl)
        filesId.push(item.fileId)
        filesName.push(item.fileName)
      })
      let params = {
            followType: this.recordRadio,
            followContent: this.recordContent,
            customerId: this.tableRow.customer_id,
            chanceId: this.tableRow.id,
            fileIds: filesId.join(','),
            fileUrl: filesUrl.join(','),
            fileNames: filesName.join(',')
          };
      await addChanceFollow(params);
      this.filesList = []
      this.$emit("chanceDetail", this.tableRow);
      this.recordContent = "";
      this.recordRadio = 1;
      this.$message.success("添加成功");
    },
    editBtn(name, value) {
      this.$refs["editRuleForm"].validate(async (valid) => {
        if (valid) {
          // this.promiseFun(name, value)
          new Promise((resolve) => {
            this.editParams = {
              filedName: name,
              filedValue: value,
            };
            this.editRuleForm[name] = value;
            resolve()
          }).then(() => {
            if (this.$refs[name]) {
              this.$refs[name].focus()
            }
          }).then(() => {
            this.isShowInput = name
          })
        } else {
          return;
        }
      });
    },
    async chanceStateFun(value, id) {
      let params = {
        chanceState: value ? "1" : "0",
      };
      await updateChance(params, id);
      this.$emit("chanceDetail", this.tableRow);
      this.$message.success("更新成功");
    },
    handleClose(done) {
      this.$emit("handleClose", done);
    },
    handleClick() {
      console.log(this.activeName);
    },
  },
};
</script>
<style lang="less" scoped>
.fileList-container {
  .fileList-item {
    display: flex;
    justify-content: space-between;
    width: 320px;
    .item-name {
      font-size: 12px;
      color: #333;
    }
    .deleteBtn {
      color: #1890ff;
      cursor: pointer;
    }
  }
}
.orderFile-container {
  .orderFileBtn {
    width: 100px;
    height: 32px;
    line-height: 32px;
    border:  1px solid rgba(220, 223, 230, 100);
    color: #666;
    background: #fff;
    text-align: center;
    font-size: 14px;
    position: relative;
    border-radius: 4px;
    display: inline-block;
    .orderFile-input {
      width: 100px;
      height: 32px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      cursor: pointer;
      font-size: 0;
    }
  }
}
/deep/.el-step__title {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  margin-left: -15px;
}
/deep/.el-step__icon-inner {
  font-size: 14px !important;
  width: 12px !important;
  margin-left: -2px;
}
/deep/.el-step__icon.is-icon {
  width: 10px !important;
}
.salesLeadsDetail-drawer {
  /deep/ .el-drawer__body {
    padding: 0;
  }
  .flowType {
    width: 410px;
  }
  .feedbackImg {
    width: 80px;
    margin-right: 20px;
  }
  .fileNameText {
    position: absolute;
    left: 0;
    top: 50px;
    font-size: 12px;
    color: #afafaf;
    word-break: break-all;
  }
  .steps-container {
    display: flex;
    align-items: center;
    white-space: nowrap;
    .steps-left {
      font-size: 16px;
    }
    .steps-center {
      width: 480px;
      margin: 16px 48px;
      font-size: 12px;
    }
  }
  .SubmitPlan-container {
    margin-top: 24px;

    .SubmitPlan-item,
    .feedback-item {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px dashed #797979;
      .item-row {
        display: flex;
        align-items: center;
        color: #999;
        font-size: 12px;
        .item-btn {
          height: 28px;
          font-size: 14px;
          line-height: 28px;
          text-align: center;
          border-radius: 2px;
          background: #1890ff;
          color: #fff;
          padding: 0 6px;
          margin: 10px 10px 14px;
        }
        .item-user {
          margin: 0 10px;
        }
      }
    }
    .feedback-item {
      margin-top: 16px;
      padding-top: 24px;
      border-bottom: none;
      border-top: 1px dashed #797979;
    }
  }
  .drawer-container {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    .fileNames {
      color: #1890ff;
      margin-top: 8px;
    }
    .fileImg {
      width: 80px;
      margin-top: 8px;
    }
    .drawer-left {
      padding: 32px 24px;
      text-align: left;
      width: 1150px;
      height: 100%;
      border-right: 1px solid #e8e8e8;
      box-sizing: border-box;
      overflow-x: hidden;
    }
    .drawer-right {
      width: 500px;
      overflow-y: auto;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      /deep/.el-tabs__content {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
      /deep/.el-tab-pane {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      .rightTop {
        border-bottom: 1px #eaeaeb solid;
        padding: 24px;
        font-size: 14px;
        color: #101010;
        height: 112px;
        box-sizing: border-box;
        .visitIcon {
          width: 14px;
          vertical-align: middle;
          margin-right: 8px;
        }
        .notContent {
          margin-top: 24px;
        }
      }
      /deep/ .el-tabs__content {
        padding-left: 16px;
      }
      .record-item {
        margin-top: 16px;
        border-top: 1px #eaeaeb dashed;
        padding-top: 16px;
        .item-content {
          margin-left: 22px;
          .itemBtn {
            display: inline-block;
            height: 32px;
            line-height: 32px;
            padding: 0 14px;
            border: 1px solid #1890ff;
            border-radius: 4px;
            font-size: 14px;
            color: #101010;
            margin: 16px 16px 8px 0;
          }
          .item-detail {
            color: #333;
            margin-top: 8px;
            vertical-align: middle;
            .visitIcon {
              width: 14px;
              margin-left: 8px;
            }
          }
        }
        .item-top {
          padding-right: 16px;
          .item-icon {
            display: inline-block;
            width: 4px;
            height: 4px;
            background-color: #fff;
            border: 4px #1890ff solid;
            border-radius: 50%;
            margin-right: 8px;
          }
          .item-role {
            color: #1890ff;
          }
          .item-date {
            float: right;
            color: #999;
          }
        }
      }
    }
    .detail-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .detail-tit-info {
        font-size: 18px;
      }
      
      .hoverEdit {
        display: inline-block;
        width: 333px;
        font-size: 18px;
        padding: 6px 12px;
        margin-right: 16px;
        margin-bottom: 20px;
        border-radius: 4px;
        .editColor {
          color: #1890ff;
          font-size: 18px;
        }
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 95% center
            #f2f9ff;
        }
      }
    }
    .data-title {
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      color: #000000a6;
      border-bottom: 1px dashed #ccc;
      margin-bottom: 16px;
    }
    .detail-content {
      .edit-input-container {
        display: inline-block;
        width: 323px;
        height: 32px;
        padding: 0 12px;
        font-size: 12px;
        color: #999;
        margin-right: 16px;
        border-radius: 4px;
        /deep/ .el-form-item__label {
          line-height: 32px;
        }
      }
      .edit-input {
        width: 200px;
        height: 32px;
        line-height: 32px;
        vertical-align: top;
        .cancel {
          cursor: pointer;
        }
        /deep/ .el-input__inner {
          height: 32px;
          line-height: 32px;
        }
        /deep/ .el-input-group__append {
          height: 30px;
          line-height: 30px;
        }
      }
      .noteHoverEdit {
        display: inline-flex;
        width: 580px;
        padding: 9px 48px 9px 12px;
        font-size: 12px;
        color: #999;
        margin-bottom: 22px;
        border-radius: 4px;
        .noteName {
          white-space: nowrap;
        }
        .editColor {
          vertical-align: top;
          display: inline-block;
          color: #1890ff;
          white-space: pre-wrap;
        }
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 99% center
            #f2f9ff;
        }
      }
      .hoverEdit {
        display: inline-block;
        width: 323px;
        height: 21px;
        padding: 6px 12px;
        font-size: 12px;
        color: #999;
        margin-right: 16px;
        margin-bottom: 20px;
        border-radius: 4px;
        .editColor {
          color: #1890ff;
        }
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 95% center
            #f2f9ff;
        }
      }
      .DisableEdit {
        display: inline-block;
        width: 323px;
        height: 21px;
        padding: 6px 12px;
        font-size: 12px;
        color: #999;
        margin-right: 16px;
        border-radius: 4px;
      }
    }
    .about-content {
      padding-top: 16px;
      // height: 300px;
      .fileNames {
        height: 20px;
        color: #1890ff;
        font-size: 12px;
        margin-top: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .con-rec {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .addFileBtn {
          height: 20px;
          color: #1890ff;
          font-size: 14px;
          display: flex;
          align-items: center;
          position: relative;
          margin-bottom: 8px;
          .addFileInput {
            position: absolute;
            left: 0;
            top: 0;
            width: 120px;
            height: 20px;
            opacity: 0;
            cursor: pointer;
            font-size: 0;
          }
        }
      }
    }
    .radioTab-container {
      padding-top: 10px;
      .radioTab-item {
        display: inline-block;
        height: 30px;
        line-height: 30px;
        padding: 0 16px;
        border-radius: 4px;
        border: 1px solid #ccc;
        color: rgba(0, 0, 0, 0.65);
        margin-right: 16px;
        cursor: pointer;
        // border: 1px solid #1890ff;
      }
      .radioTab-item-active {
        border: 1px solid #1890ff;
        color: #1890ff;
      }
    }
    .ServiceFollow-content {
      border: 1px solid #EBEEF5;
      margin-top: 16px;
      padding: 36px 0 0;
      border-radius: 4px;
      .ServiceFollow-item {
        padding-left: 20px;
        margin-bottom: 20px;
        height: 52px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
      .feedback {
        color: #1890ff;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
      }
      .feedback-content {
        padding: 20px;
        margin-top: 16px;
        text-align: left;
        .upload-userIcon {
          width: 88px;
          height: 32px;
          padding: 0;
          position: relative;
          display: inline-block;
          margin: 24px 20px 0 20px;
        }
      }
    }
  }

  /deep/ .el-drawer__open .el-drawer.rtl {
    width: 1656px !important;
  }
}
.dialog-input {
  width: 320px !important;
}
.commit {
  height: 60px;
  .commit-btn {
    float: right;
    margin-top: 24px;
  }
}
.ml{
  margin-left: 24px;
}
.imgs{
  width: 24px;
  height: 24px;
  background-color: #ffdbdc;
  border-radius: 50%;
  vertical-align: middle;
}
.diatit{
  margin-left: 12px;
  font-size: 20px;
  color: #333;
}
.diacon{
  font-size: 14px;
  color: #101010;
  margin-left: 10px;
}
</style>
